<template>
  <div class="privacy">
    <!-- 头部 -->
    <logged-heard></logged-heard>
    <!-- 搜索 -->
    <div class="search">
      <search
        :hot="false"
        :job="job"
        :jobCity="jobCity"
        :placeholder="placeholder"
        :particular="particular"
        @changeCity="changeCity"
        @threeCategory="threeCategory"
        @searchClick="searchClick"
        @handleChange="handleChange"
        :optionsValue="optionsValue"
        :posiName="posiName"
      ></search>
    </div>
    <div class="neirong">
      <div class="left-neirong">
        <div class="Job-listings" v-for="item in itemList" :key="item.id">
          <div class="top pointer" @click="navTo('/Position-details', item.id)">
            <div class="list">
              <!-- 后续根据跳转参数进行判断是哪一类 急招，实习 -->
              <span
                >{{ item.position_name }}【{{ item.province }}】<span
                  style="text-white bg-blue"
                  class="el-list-span"
                  v-if="query.internship == 1 || query.is_recruit == 1"
                  >{{ categoryShow ? "急招" : "实习" }}</span
                ></span
              >
              <div class="askfor">
                <span v-if="item.salary_min == '面议'">{{ item.salary_min }}</span>
                <span v-else>{{ item.salary_min }}-{{ item.salary_max }}</span>
                &nbsp;
                <span>{{ item.experience }}</span>&nbsp; <span>{{ item.education }}</span>
              </div>
            </div>
            <div class="right-message">
              <div class="company">
                <span>{{ item.ent.ent_name }}</span>
                <span
                  >{{ item.ent.other_info.nature.text }}:{{
                    item.ent.other_info.scale.text
                  }}</span
                >
              </div>
              <div class="right-img">
                <img :src="item.ent.logo_url" alt="" />
              </div>
            </div>
          </div>
          <div class="social-benefits">
            <div
              class="left"
              v-for="(welfareItem, index) in item.ent.welfare.slice(0, 12)"
              :key="index"
            >
              <span>{{ welfareItem }}</span>
              <span v-if="index == 11">...</span>
            </div>
            <!-- 点这进行聊天 -->
            <div
              class="right pointer"
              v-show="userDetail.enterprise_id!==item.enterprise_id"
              @click="
                sendClick(
                  item.hr.user_id,
                  item.id,
                  item.ent.ent_name,
                  item.enterprise_id,
                  item.ent.logo_url,
                  item.hr.user_name,
                  item.position_name,
                  item.hr.id
                )
              "
            >
              <span>HR·{{ item.hr.user_name }}｜{{ item.hr.online_status }}</span>
            </div>
          </div>
        </div>
        <div class="Job-not" v-if="itemList.length == 0">暂无您搜索的职位</div>
        <!-- 分页 -->
        <div class="pading">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :hide-on-single-page="valuePageShow"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>

      <div class="right-login">
        <div class="el-login">
          <register
            :showMessage="false"
            :showCompany="true"
            :readList="readList"
          ></register>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <feet-model></feet-model>
  </div>
</template>

<script>
import TIM from "tim-js-sdk";
import getCurrentCityName from "@/util/location";
import loggedHeard from "../../components/loggedHeard.vue";
import feetModel from "../../components/feet.vue";
import tabbarModel from "../../components/tabbar.vue";
import search from "../../components/search.vue";
import register from "../../components/register.vue";
import api from "../../api/position";
import toolApi from "../../api/tool";
import { mapGetters, mapState } from "vuex";

export default {
  components: { loggedHeard, feetModel, tabbarModel, search, register },
  computed: {
    ...mapGetters(["hasLogin"]),
    ...mapState(["userDetail"]),
  },
  data() {
    return {
      query: {
        page: 1,
        pageSize: 10,
        is_mine: 0,
        is_recruit: 0,
        internship: 0,
        position_id: "",
        is_home: 1,
        province_id: "",
        nature: "",
        scale: "",
        industry: "",
        position_name: "",
      },
      total: 0,
      itemList: [],
      valuePageShow: true,
      categoryShow: false,
      jobCity: false,
      job: true,
      particular: true,
      readList: [],
      placeholder: "",
      optionsValue: [],
      posiName: "搜索职类",
    };
  },

  created() {
    let query = this.$route.query;
    if (query.category == 1) {
      this.categoryShow = true;
      this.query.is_recruit = 1;
      this.getCity();
    } else if (query.category == 2) {
      this.categoryShow = false;
      this.query.internship = 1;
      this.getCity();
    }
    if (query.id) {
      this.query.position_id = query.id;
      this.posiName = query.position;
      this.getCity();
    }

    if (Object.keys(query).length == 0) {
      this.job = true;
      this.jobCity = false;
      this.particular = true;
    } else if (!query.optionsValue) {
      this.job = false;
      this.jobCity = true;
      this.particular = false;
    }
    if (query.position_name) {
      this.query.position_name = query.position_name;
      this.getCity();
    }
    if (query.optionsValue) {
      this.query.position_id = JSON.parse(query.optionsValue)[1];
      this.optionsValue = JSON.parse(query.optionsValue);
      this.posiList();
    }
    if (this.hasLogin) {
      this.readRecord();
    }
    if (Object.keys(query).length == 0) {
      this.posiList();
    }
  },

  mounted() {},

  methods: {
    getCity() {
      getCurrentCityName().then((res) => {
        this.placeholder = res;
        this.addressClick();
      });
    },
    // 获取地址
    addressClick() {
      toolApi.getAddress().then((res) => {
        if (res.code == 200) {
          let city = res.data.children.map((item) => {
            return {
              value: item.id,
              label: item.label,
            };
          });
          let cityOne = city.filter((item) => {
            return item.label == this.placeholder;
          });
          this.query.province_id = cityOne[0].value;
          this.posiList();
        }
      });
    },
    // 看过的职位
    readRecord() {
      api.readRecord().then((res) => {
        if (res.code == 200) {
          this.readList = res.data.data;
        }
      });
    },
    sendClick(id, posiId, entName, ent_id, ent_logo, contacts, jop, hr_id) {
      if (!this.hasLogin) {
        this.$util.msg("请先登录", "warning");
        return;
      }
      if (this.userDetail.detail.length == 0 || !this.userDetail.detail) {
        this.$util.msg("请先完善简历", "warning");
        return;
      } else {
        if (this.userDetail.detail.completeness < 90) {
          this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
          return;
        }
      }
      if(this.userDetail.id==hr_id)return
      if (this.userDetail.user_type !== 1) {
        this.$emit('toggleClick')
        return;
      }
      let query = {
        user_id: this.userDetail.id, //用户id
        user: this.userDetail.name, //用户名称
        head: this.userDetail.avatar_url, //用户头像
        ent_name: entName, //企业名称
        ent_id, //企业id
        ent_logo, //企业logo
        contacts, //企业hr名字
        jopId: posiId, //工作id
        jop, //职位名称
        customStatus: 1, //沟通状态
        id: hr_id, //hrid
      };
      let message = this.$tim.createCustomMessage({
        to: id.toString(),
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: "text",
          description: "打扰了,希望和你聊聊这个职位，是否方便呢",
          extension: JSON.stringify(query),
        },
      });
      // 2. 发送消息
      let promise = this.$tim.sendMessage(message);
      promise
        .then((imResponse) => {
          this.$router.push({ path: "/interaction", query: { id, posiId } });
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    searchClick(text) {
      // console.log(text);
      this.query.position_name = text;
      this.query.page = 1;
      this.posiList();
    },
    // 根据企业性质筛选
    threeCategory(id, text) {
      if (text == "scale") {
        this.query.scale = id;
      } else if (text == "nature") {
        this.query.nature = id;
      } else {
        this.query.industry = id;
      }
      this.query.page = 1;
      this.posiList();
    },
    handleChange(id) {
      this.query.position_id = id;
      this.query.page = 1;
      this.posiList();
    },
    // 城市筛选
    changeCity(id) {
      this.query.province_id = id;
      this.query.page = 1;
      this.posiList();
    },
    navTo(path, id) {
      if (id) {
        this.$router.push({ path, query: { id } });
        return;
      }
      this.$router.push({ path });
    },
    // 获取职位列表
    posiList() {
       const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      api.posiList(this.query).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.itemList = res.data.data;
          if (res.data.last_page > 1) {
            this.valuePageShow = true;
          }
        }
        loading.close()
      });
    },
    // 页数跳转
    currentChange(val) {
      this.query.page = val;
      this.posiList();
    },
  },
};
</script>
<style lang="less" scoped>
.privacy {
  width: 100%;
  background: #f6f6f8;
  .search {
    width: 100%;
    padding-left: 9.8%;
  }
  .neirong {
    display: flex;
    .left-neirong {
      width: 57%;
      margin-left: 9.8%;
      display: flex;
      flex-direction: column;
      position: relative;
      .Job-not {
        text-align: center;
        margin-top: 100px;
      }
      .Job-listings {
        width: 100%;
        float: left;
        height: 8.8125rem;

        background-color: #fff;
        margin-bottom: 0.625rem;
        .top {
          display: flex;
          height: 6rem;
          margin-bottom: 0.8125rem;
          border-bottom: 0.0625rem solid #eeeeee;
        }
        .list {
          display: flex;
          flex-direction: column;
          padding: 1.625rem 1.875rem;
          line-height: 1.5rem;
          width: 41rem;
          span {
            font-weight: bold;
            color: #126bf9;
            cursor: pointer;
          }
          .el-list-span {
            width: 47px;
            display: inline-block;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background: #126bf9;
            color: #fff;
            border-radius: 10px;
            font-weight: bold;
            color: #ffffff;
            font-size: 15px;
          }
          .askfor {
            font-size: 0.9375rem;

            font-weight: 400;
            color: #61687c;
            span:nth-child(1) {
              font-size: 1rem;

              font-weight: 400;
              color: #fd7240;
            }
            span:nth-child(2) {
              font-size: 0.9375rem;

              font-weight: 400;
              color: #61687c;
            }
            span:nth-child(3) {
              font-size: 0.9375rem;

              font-weight: 400;
              color: #61687c;
            }
          }
        }
        .right-message {
          display: flex;
          // padding-left: 4.25rem;
          .company {
            width: 21.5rem;
            display: flex;
            flex-direction: column;
            font-size: 1rem;
            overflow: hidden;
            font-weight: bold;
            line-height: 1.5625rem;
            color: #126bf9;
            padding: 1.25rem 1.25rem;
            span:nth-child(2) {
              font-size: 0.875rem;
              font-weight: 400;
              color: #61687c;
            }
          }
          .right-img {
            font-size: 0.875rem;
            font-weight: 400;
            color: #61687c;
            img {
              width: 3.5rem;
              height: 3.5rem;
              margin-top: 1.25rem;
            }
          }
        }
      }
      .pading {
        margin: 0 auto;
        margin-bottom: 10px;
        ::v-deep.btn-prev {
          background: #fff;
        }
        ::v-deep .el-pagination.is-background .btn-next,
        .el-pagination.is-background .btn-prev,
        .el-pagination.is-background .el-pager li {
          background: #fff;
        }
        ::v-deep .el-pagination.is-background .el-pager li {
          background: #fff;
          color: #414a60;
        }
        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
          background: #126bf9;
          color: #fff;
        }
      }
    }

    .social-benefits {
      margin-left: 12px;
      .left {
        float: left;
        font-size: 0.875rem;

        font-weight: 400;
        color: #61687c;
        span {
          padding: 0.875rem;
        }
      }
      .right {
        // width: 10.125rem;
        padding: 0 0.9375rem;
        height: 1.4375rem;
        margin-right: 1.25rem;
        border: 0.0625rem solid #f3f3f3;
        border-radius: 12px;
        text-align: center;
        float: right;
        font-size: 0.9375rem;

        font-weight: 400;
        color: #6c7388;
      }
    }
    .right-login {
      width: 19.8125rem;
      height: 41.6875rem;
      margin-left: 1.25rem;
      float: left;
      align-items: flex-start;
      margin-bottom: 1.25rem;

      .el-login {
        width: 317px;
        height: 347px;
        // background: #126bf9;
        margin-bottom: 1.25rem;
      }
      .have-seen {
        width: 19.8125rem;
        height: 19.5rem;
        background: #ffffff;
      }
    }
  }
}
</style>
